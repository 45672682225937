.catalog {
    font-family: arial, helv;
    overflow-y: scroll; /* allow momentum scrolling on phones */
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
}

.bookContainer {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.catalog.bookContainer>*{
    flex:0;
}

.catalog .bookentry {
    flex: 1;
}

.catalog .nextCategory {
    text-align: center;
}
