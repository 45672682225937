.dropdown {
    display: flex;
}

.OrderReview>div:not(.popup-box) {
    margin: 1em;
}

.OrderReview>div:not(.popup-box)>* {
    margin: 5px;
}

.info {
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
}

.deleted {
    background-color: var(--secondary-color);
}

.info>div {
    width: 100%;
    border-top: solid black 2px;
    height: -webkit-fill-available;
    padding: 1em;
}

.info>div>div {
    border-bottom: solid black 2px;
}

.booklist {
    text-align: left;
    width: 60%;
}

.booklist>ul>li>details {
    width: 95%;
}

.booklist details>div {
    padding: 1em;
}

.booklist>ul {
    list-style-type: none;
}

details {
    width: 70%;
    margin: 0 auto;
    background: var(--accent-color);
    margin-bottom: 0.5rem;
    box-shadow: 0 0.1rem 1rem -0.5rem rgba(0, 0, 0, .4);
    border-radius: 5px;
    overflow: hidden;
}

summary {
    padding: 1rem;
    display: flex;
    background: var(--secondary-color);
    padding-left: 2.2rem;
    position: relative;
    cursor: pointer;
    justify-content: space-between;
    text-align: left;
}

summary:before {
    content: '';
    border-width: 0.4rem;
    border-style: solid;
    border-color: transparent transparent transparent var(--low-color);
    position: absolute;
    top: 1.3rem;
    left: 1rem;
    transform: rotate(0);
    transform-origin: 0.2rem 50%;
    transition: 0.25s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
details[open] > summary:before {
    transform: rotate(90deg);
}