/**
 * ==============================================
 * Color Palett
 * ==============================================
 */
:root {
  --base-color: #ebded0;
  --secondary-color: #fad4b059;
  --bg-edge-color: #ece2d1;
  --corner-color: #e8c675;
  --base-text-color: #e8c675;
  --low-color: #daa352;
  --high-color: hsl(42, 71%, 68%);
  --disabled-percent: 50%;
  --accent-color: #eeeeee00;
}

body {
  margin: 0;
  background-color: var(--bg-edge-color);
  font-size: 20px;           /* This is for MOBILE devices */
  /* the following permits momentum scroll on phones */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

button, input, textarea {
  font-size: 30px;           /* This is for MOBILE devices */
}

button {
  border: 3px outset #e1b857;
  border-radius: 4px;
}

hr {
  border-top: 1px solid #cfa532;
  border-bottom: 1px solid #e8c675;
}

#root {
  background-image: linear-gradient(to right, var(--bg-edge-color), white, var(--bg-edge-color));
  margin: 6px;
}

#logo {
  cursor: pointer;
}

.App {
  /* text-align: center; */
  border: 7px double var(--corner-color);
  font-family: 'Georgia',serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 24px);
}

.App > div:first-child {
  text-align: center; /* Center the LOGO IMAGE and TEXT */
  padding-top: 1rem;
}

.bg {
  background-position: 50% 50%;
  background-size: cover;
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 100px;
  
}

.topleft {  
  left: 0;
  top: 0;
}

.topright {
  right: 0;
  top: 0;
  transform: rotate(90deg);
}

.bottomleft {  
  left: 0;
  bottom: 0;
  transform: rotate(270deg);
}

.bottomright {
  right: 0;
  bottom: 0;
  transform: rotate(180deg);
}

.topname { 
  font-weight: bold;
  font-size: 1.1em;
}

.topaddr { 
  padding-top: 0.3em;
}

.toptext { 
  padding-bottom: 1em;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

button.linklike {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  text-transform: none;
}

.even {
  background-color: var(--secondary-color);
}

.odd {
  background-color: var(--accent-color);
}

.sticky {
  position: sticky;
}

.sticky.top {
  top: 0;
}

.sticky.bottom {
  bottom: 0;
}

.header {
  z-index: 100;
}

.footer {
  font-size: 0.7em;
  width: 100%;
  padding-bottom: 1rem;
  background-color: var(--base-color);
  color: var(--base-text-color);
  text-align: center;
  z-index: 100;
}


/**
 * ==============================================
 * cool buttons
 * ==============================================
 */

button {
  background: var(--high-color);
  -webkit-box-shadow: 0 3px 13px -2px rgba(0, 0, 0, .15);
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, .15);
  color: #000;
  font-family: 'Ubuntu', sans-serif;
  outline: none;
  padding: 0.7rem;
  text-transform: uppercase;
  transition: 0.4s ease;
}

button:hover:not(:disabled) {
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 0.2rem var(--low-color);
  box-shadow: 0 0 0 0.2rem var(--low-color);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);  
}

button:active:not(:disabled) {
  -webkit-box-shadow: 0 0 0 0.2rem var(--low-color);
  box-shadow: 0 0 0 0.2rem var(--low-color);
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  transition: 0.1s ease;
  transform: translatey(0.25em) scale(0.98);
  border-style: inset;
}

button:disabled, button:disabled > *{
  filter: opacity(50%);
}

/*********** MEDIA QUERIES -- see https://stackoverflow.com/a/7354648/1812732 *********/
@media (min-width:990px)  {
  body {
    font-size: 17px; 
  }  
  button, input, textarea {
    font-size: 17px;
  }
  .add-to-cart-button span {
    display: block;
  }
}