/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
 .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2px 0;
    overflow: hidden;
    width: 100%;
    min-width: 50px;
  }
 
 .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    min-height: 10px;
    border-radius: 5px;
    background-color: var(--low-color);
    color: var(--low-color);
    box-shadow: 9999px 0 0 -5px var(--low-color);
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
  }
  
  .dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--low-color);
    color: var(--low-color);
  }
  
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px var(--low-color);
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
  
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px var(--low-color);
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }
  
  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px var(--low-color);
    }
    30% {
      box-shadow: 9984px 0 0 2px var(--low-color);
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px var(--low-color);
    }
  }
  
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px var(--low-color);
    }
    30% {
      box-shadow: 9999px 0 0 2px var(--low-color);
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px var(--low-color);
    }
  }
  
  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px var(--low-color);
    }
    30% {
      box-shadow: 10014px 0 0 2px var(--low-color);
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px var(--low-color);
    }
  }