.bookentry {
  display: flex;
  flex-flow: row nowrap;
  min-height: 90px;
}

.bookentry div.main {
  flex: 1;
  padding: 0 0.5em;
}
.bookentry div.main .author {
  font-style: italic;
}
.bookentry div.main .title {
  font-weight: bold;
  text-transform: uppercase;
}
.bookentry div.main .price {
  font-weight: bold;
  color: #000080;
}
.bookentry div {
  flex: 0;
}
.bookentry .caption {
  font-size: 0.5em;
}
.bookentry .inactiveLink {
  pointer-events: none;
  cursor: default;
}

.bookentry .bookImage:not(:hover) {
  height: 90px;
}

.bookentry :not(.inactiveLink) .bookImage:hover {
  height: 300px;
}

.bookentry .bookImage {
  transition: all .5s ease;
}
