.search {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    row-gap: 1em;
    padding-bottom: 10px;
    background-color: var(--base-color);
    /* color: var(--base-text-color); */
    text-align: center;
    font-family: arial;    
    /* font: 1em arial; border: 5px ridge #e8c675; */
    margin: 0 1em 1em;    
    
}

.search .field {
    padding: 0.7rem;
    border: none;
    border-radius: 4px;
}

.search .specialsearch {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    gap: 3em;
}

.search .infotext {
    width: 98%;
    padding: 0 3em;
}

.search button {
    margin: 5px;
}

.shoppingcart {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

@keyframes rubberBand {
    from {
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      transform: scale3d(.95, 1.05, 1);
    }
  
    75% {
      transform: scale3d(1.05, .95, 1);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
  }
  
.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
}