.categories {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin: auto;
}

.categories>button {
    flex-grow: 1;
    text-size-adjust: 3px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    text-align: left;
    margin: 2px;
    border-radius: 10px;
}

.categories>button:hover {
    transform: scale(1.1);
}