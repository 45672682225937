.order {
    display: flex;
    flex-direction: row-reverse;
    width: 70%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.hidden {
    visibility: hidden;
}

.error { 
    border: 2px solid red;
    background-color: rgba(255, 0, 0, .2);
}

.order button {
    margin: 5px;
}

.confirm {
    width: 30%;
    margin-left: 30%
}

.cart {
    width: 100%;
}

.cart>div {
    border-radius: 5px;
}

.confirm>div {
    border-top: 1px black solid;
}

.cart, .confirm {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.cart>div, .confirm>div {
    display: flex;
    justify-content: space-between;
}

.cart>div:first-child {
    justify-content: center;
}

.cart>div>button {
    width: 12em;
    white-space: nowrap;
    flex: none;
    height: fit-content;
}

.address {
    display: flex;    
    width: 50%;
    max-width: 600px;
    flex-wrap: wrap;
    max-height: 140px;
    justify-content: space-between;
}

.address>input, .address>label {
    margin: 5px 5px;
    height: 20px;
    
}

.address>.full {
    width: 100%;
    min-width: 170px;
}

.address>.half {
    width: 45%;
    min-width: 100px;
}

.address>.third {
    width: 29%;
    min-width: 70px;
}

.message {
    width: 40%;
    margin-right: 10%;
}

.message>textarea {
    width: 100%;
    height: 200px;
}

.buttons {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.buttons>div, .buttons>button, .buttons>small {
    width: 50%;
    margin: 5px;
    max-width: 299px;
}

.order .small {
    font-size: 0.8em;
}

#paypalbutton {
    width: 50%;
    max-width: 299px;
    margin: 5px;
}