/* from https://www.codewithrandom.com/2021/10/add-to-cart-button-add-to-cart-html-css.html */
.add-to-cart-button {
    background: #e8c675;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 3px 13px -2px rgba(0, 0, 0, .15);
    box-shadow: 0 3px 13px -2px #50301bd0;
    color: #000;
    display: flex;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    justify-content: space-around;
    min-width: 3em;
    min-height: 1.5em;
    overflow: hidden;
    outline: none;
    padding: 0.7rem;
    position: relative;
    text-transform: uppercase;
    transition: 0.4s ease;
    width: auto;
    margin: 5px;
}

.add-to-cart-button span {
  display: none;
  margin-left: 36px;
  position: relative;
}

.add-to-cart-button:active {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(252, 186, 3, .45);
    box-shadow: 0 0 0 0.2rem rgba(252, 186, 3, .45);
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
}
.add-to-cart-button:hover {
    cursor: pointer;
}
.add-to-cart-button:hover, .add-to-cart-button:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(252, 186, 3, .45);
    box-shadow: 0 0 0 0.2rem rgba(252, 186, 3, .45);
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
}
.add-to-cart-button.added {
    background: #2fbf30;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(11, 252, 3, 0.45);
    box-shadow: 0 0 0 0.2rem rgba(11, 252, 3, 0.45);
}
.add-to-cart-button.added .cart-icon {
    animation: drop 0.3s forwards;
    -webkit-animation: drop 0.3s forwards;
    animation-delay: 0.18s;
}
.add-to-cart-button.added .box-1, .add-to-cart-button.added .box-2 {
    top: 18px;
}
.add-to-cart-button.added .tick {
    animation: grow 0.6s forwards;
    -webkit-animation: grow 0.6s forwards;
    animation-delay: 0.7s;
}
.add-to-cart-box {
    height: 5px;
    position: absolute;
    top: 0;
    width: 5px;
}
.box-1, .box-2 {
    transition: 0.4s ease;
    top: -8px;
}
.box-1 {
    left: 23px;
    transform: rotate(45deg);
}
.box-2 {
    left: 32px;
    transform: rotate(63deg);
}
.cart-icon {
    left: 15px;
    position: absolute;
    top: 8px;
}
.tick {
    background: #146230;
    border-radius: 50%;
    position: absolute;
    left: 28px;
    transform: scale(0);
    top: 5px;
    z-index: 2;
}
@-webkit-keyframes grow {
    0% {
        -webkit-transform: scale(0);
   }
    50% {
        -webkit-transform: scale(1.2);
   }
    100% {
        -webkit-transform: scale(1);
   }
}
@keyframes grow {
    0% {
        transform: scale(0);
   }
    50% {
        transform: scale(1.2);
   }
    100% {
        transform: scale(1);
   }
}
@-webkit-keyframes drop {
    0% {
        -webkit-transform: translateY(0px);
   }
    100% {
        -webkit-transform: translateY(1px);
   }
}
@keyframes drop {
    0% {
        transform: translateY(0px);
   }
    100% {
        transform: translateY(1px);
   }
}
/* Page style */
.container {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
}
